import React from 'react';
import TextPage, { TextPageSection } from '../templates/text-page';

const About = () => (
  <TextPage title="Submit">
    <TextPageSection title="Submit your work">
      <p>
        <a
          href="https://zke2dyv8z7s.typeform.com/to/m7quHupi?typeform-source=dothegreenthing.com"
          target="_blank"
          rel="noreferrer"
        >
          Submit
        </a>
        {' '}
        your own artwork and help us continue our creative investigation.
      </p>
    </TextPageSection>
  </TextPage>
);

export default About;
